<template>
  <div>
    <pro-document-breadcrumbs
      icon="perm_identity"
      :breadcrumbs="breadcrumbs"
      user-role="procurer"
    ></pro-document-breadcrumbs>
    <div class="row justify-end" style="padding: 10px 10px 0 0">
      <q-btn no-caps color="primary" @click="batch">
        <div class="ellipsis">
          {{ getRes("menu.Batch-EditSupplierStatus") }}
        </div>
      </q-btn>
    </div>
    <pro-report
      title="CustMenu.Title.SearchSupplier"
      :columns="columns"
      v-model="fullList"
      row-key="id"
      auto-search
      :loading="loading"
      file-name="Change_Supplier_Status_"
      :extension-download="[
        {
          icon: 'download',
          downloadAll: true,
          hiddenColumn: ['button', 'buttons'],
          appendColumn: [
            {
              name: 'change To',
              label: 'change To',
              type: 'string',
            },
          ],
        },
      ]"
    >
      <template #body-cell-status="{ col, value }">
        <q-td style="width: 200px" :class="col.__tdClass" :style="col.style">
          <span
            :class="value === getRes('Document.Status.Inactive') ? 'red' : ''"
          >
            {{ value }}
          </span>
        </q-td>
      </template>

      <template #body-cell-button="{ row, col }">
        <q-td auto-width :class="col.__tdClass">
          <pro-button
            label="System.Button.EditStatus"
            icon="PROSmart-Edit"
            @click="editStatus(row)"
            v-if="!['PendingForApproval', 'Purged'].includes(row.statusEng)"
          />
        </q-td>
      </template>

      <template #body-cell-buttons="{ row, col }">
        <q-td auto-width :class="col.__tdClass">
          <pro-button
            v-if="row.statusEng === 'Approved'"
            label="System.Button.EditCompanyInfo"
            icon="PROSmart-CompanyEdit"
            :to="{
              name: 'EditSupplierInfo',
              params: {
                userId: row.hcmUserId,
              },
            }"
          />
        </q-td>
      </template>
    </pro-report>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProReport from "@/components/PROSmart/Report/ProReport";
import ProButton from "@/components/PROSmart/ProButton";

export default {
  components: {
    ProDocumentBreadcrumbs,
    ProReport,
    ProButton,
  },
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          name: "menu.SupplierManagement",
          icon: "PROSmart-Account",
        },
      ],
      fullList: [],
      columns: [
        {
          name: "hcmUserId",
          i18NLabel: "CustMenu.Login.SupplierCode",
          field: "hcmUserId",
          align: "left",
          sortable: true,
          searchable: true,
          type: "string",
        },
        {
          name: "supplierCompayName",
          i18NLabel: "Form.Field.SupplierCompanyName",
          field: "supplierCompayName",
          align: "left",
          searchable: true,
          type: "string",
          style: " word-break: break-all;width: 400px",
        },
        {
          name: "status",
          i18NLabel: "Document.Status",
          field: "status",
          align: "left",
          searchable: true,
          type: "chip",
          list: [
            {
              label: this.getRes("Document.Status.Registering"),
              value: this.getRes("Document.Status.Registering"),
            },
            {
              label: this.getRes("Document.Status.PendingForApproval"),
              value: this.getRes("Document.Status.PendingForApproval"),
            },
            {
              label: this.getRes("Document.Status.Approved"),
              value: this.getRes("Document.Status.Approved"),
            },
            {
              label: this.getRes("Document.Status.Inactive"),
              value: this.getRes("Document.Status.Inactive"),
            },
            {
              label: this.getRes("Document.Status.Purged"),
              value: this.getRes("Document.Status.Purged"),
            },
          ],
        },
        {
          name: "button",
          label: "",
          field: "button",
          searchable: false,
          type: "string",
        },
        {
          name: "buttons",
          label: "",
          field: "buttons",
          searchable: false,
          type: "string",
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    await this.$proSmart.supplierUi.getAllSupplier(this).then((b) => {
      this.fullList = b.map((item) => {
        let name;
        if (localStorage.getItem("Language") === "English") {
          name = item.cmpyNameEng;
        } else {
          name = item.cmpyNameChi;
        }
        return {
          id: item.id,
          supplierCode: item.id.toString(),
          supplierCompayName: name,
          status: this.getRes(`Document.Status.${item.status}`),
          statusEng: item.status,
          hcmUserId: item.hcmUserId,
        };
      });
      this.loading = false;
    });
  },
  methods: {
    editStatus(row) {
      this.$router.push({
        name: "EditSupplierStatus",
        params: {
          id: row.id,
          supplierCode: row.supplierCode,
          supplierCompayName: row.supplierCompayName,
          status: row.status,
          statusEng: row.statusEng,
          hcmUserId: row.hcmUserId,
        },
      });
    },
    batch() {
      this.$router.push({
        name: "BatchEditStatus",
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
</style>
