var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('pro-document-breadcrumbs',{attrs:{"icon":"perm_identity","breadcrumbs":_vm.breadcrumbs,"user-role":"procurer"}}),_c('div',{staticClass:"row justify-end",staticStyle:{"padding":"10px 10px 0 0"}},[_c('q-btn',{attrs:{"no-caps":"","color":"primary"},on:{"click":_vm.batch}},[_c('div',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(_vm.getRes("menu.Batch-EditSupplierStatus"))+" ")])])],1),_c('pro-report',{attrs:{"title":"CustMenu.Title.SearchSupplier","columns":_vm.columns,"row-key":"id","auto-search":"","loading":_vm.loading,"file-name":"Change_Supplier_Status_","extension-download":[
      {
        icon: 'download',
        downloadAll: true,
        hiddenColumn: ['button', 'buttons'],
        appendColumn: [
          {
            name: 'change To',
            label: 'change To',
            type: 'string',
          } ],
      } ]},scopedSlots:_vm._u([{key:"body-cell-status",fn:function(ref){
    var col = ref.col;
    var value = ref.value;
return [_c('q-td',{class:col.__tdClass,staticStyle:{"width":"200px"},style:(col.style)},[_c('span',{class:value === _vm.getRes('Document.Status.Inactive') ? 'red' : ''},[_vm._v(" "+_vm._s(value)+" ")])])]}},{key:"body-cell-button",fn:function(ref){
    var row = ref.row;
    var col = ref.col;
return [_c('q-td',{class:col.__tdClass,attrs:{"auto-width":""}},[(!['PendingForApproval', 'Purged'].includes(row.statusEng))?_c('pro-button',{attrs:{"label":"System.Button.EditStatus","icon":"PROSmart-Edit"},on:{"click":function($event){return _vm.editStatus(row)}}}):_vm._e()],1)]}},{key:"body-cell-buttons",fn:function(ref){
    var row = ref.row;
    var col = ref.col;
return [_c('q-td',{class:col.__tdClass,attrs:{"auto-width":""}},[(row.statusEng === 'Approved')?_c('pro-button',{attrs:{"label":"System.Button.EditCompanyInfo","icon":"PROSmart-CompanyEdit","to":{
            name: 'EditSupplierInfo',
            params: {
              userId: row.hcmUserId,
            },
          }}}):_vm._e()],1)]}}]),model:{value:(_vm.fullList),callback:function ($$v) {_vm.fullList=$$v},expression:"fullList"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }